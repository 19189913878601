import styled from '@emotion/styled';
import breakpoints from '../../utils/responsive';

const Styles = styled.div({
  [breakpoints.Phablet]: {
    '.calendly-inline-widget': {
      minWidth: '320px',
    },
  },

  [breakpoints.Desktop]: {
    '.calendly-inline-widget': {
      minWidth: '360',
    },
  },
});

export default Styles;
