import React from 'react';
import { graphql, Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import { useQueryParam } from 'gatsby-query-params';

import * as Globals from '../../globals';
import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
import ContentCol from '../components/contentCol/contentCol';
import CalendlyEmbed from '../components/calendlyEmbed/calendlyEmbed';
import Styles from './office-hours.styles';
import Img from 'gatsby-image';

const OfficeHoursPage = ({ data }) => {
  let backgroundFluidImageStack = [
    data.background.childImageSharp.fluid,
    Globals.backgroundOverlay,
  ].reverse();

  const utmSource = useQueryParam('utm_source', '');
  const utmMedium = useQueryParam('utm_medium', '');
  const utmCampaign = useQueryParam('utm_campaign', '');
  const utmContent = useQueryParam('utm_content', '');

  return (
    <Layout>
      <Seo
        title="Generativ Office Hours"
        description="Pick our brain about
                  what to build, how much to spend, when to launch, and what you
                  need to go from zero to market."
      />
      <Styles>
        <BackgroundImage Tag="header" fluid={backgroundFluidImageStack}>
          <Container>
            <Row>
              <ContentCol>
                <h1>Office Hours For Founders With A Great Idea</h1>
              </ContentCol>
            </Row>
          </Container>
        </BackgroundImage>

        <Container>
          <section>
            <Row>
              <Col xs={{ span: 10, offset: 1 }} lg={{ span: 5, offset: 0 }}>
                <h2>Have a project you're passionate about?</h2>

                <p>
                  Here's your chance to chat with an industry-leading CTO and
                  CPO / angel investor about your business. Pick our brain about
                  what to build, how much to spend, when to launch, and what you
                  need to go from zero to market.
                </p>

                <div className="d-none d-lg-block">
                  <Link to="/work/">See Our Work</Link>

                  <Link to="/about/">More About Us</Link>
                </div>
              </Col>
              <Col
                xs={{ span: 12 }}
                md={{ span: 10, offset: 1 }}
                lg={{ span: 6, offset: 1 }}
              >
                <div className="form-container">
                  <Spinner animation="grow" size="sm" />
                  <CalendlyEmbed
                    account="generativ"
                    eventName="office-hours"
                    utmSource={utmSource}
                    utmMedium={utmMedium}
                    utmCampaign={utmCampaign}
                    utmContent={utmContent}
                    className="calendly-embed"
                  />
                </div>
              </Col>
            </Row>
          </section>

          <hr className={'my-5 my-md-6'} />

          <Row className="testimonial-row mb-5 mb-md-6">
            <Col>
              <Img
                fluid={data.katie.childImageSharp.fluid}
                alt="Katie, Founder of The Difference"
                className="rounded-circle"
              />
            </Col>
            <Col>
              <blockquote>
                <p>
                  <span>&ldquo;</span>I was impressed with the expertise offered
                  by Josh and Jon, the Generativ founders, in just 30 minutes of
                  attending their office hours. It is clear that both are
                  knowledgeable about the early-stage founder's technical
                  development and fundraising needs, truly committed to
                  supporting non-technical founders in achieving their vision,
                  and easy-going, approachable guys to top it off. Based on my
                  interaction, I would suspect that a non-technical
                  founder&mdash;any founder, really&mdash;would have a great and
                  productive experience collaborating with Generativ.
                </p>
                <footer>
                  Katie, Founder of{' '}
                  <a
                    href="https://thedifference.co/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    The Difference
                  </a>
                </footer>
              </blockquote>
            </Col>
          </Row>

          <hr />
        </Container>
      </Styles>
    </Layout>
  );
};

export const query = graphql`
  query {
    background: file(relativePath: { eq: "backgrounds/bg-office-hours.png" }) {
      ...imageFluidExtraLarge
    }
    katie: file(relativePath: { eq: "founders/founder-katie.jpeg" }) {
      ...imageFluidSmallGrayscale
    }
  }
`;

export default OfficeHoursPage;
