import styled from '@emotion/styled';
import breakpoints from '../utils/responsive';
import rhythm from '../utils/rhythm';

const Styles = styled.main({
  // Todo: Dry out landing page styles
  section: {
    h2: {
      marginTop: `${rhythm(2)}`,
      marginBottom: `${rhythm(1 / 2)}`,
    },

    p: {
      marginBottom: `${rhythm(3 / 2)}`,
    },

    a: {
      display: 'inline-block;',
      marginRight: `${rhythm(1)}`,

      '&:last-child': {
        marginRight: 0,
      },
    },

    '.form-container': {
      borderRadius: `${rhythm(1 / 8)}`,
      overflow: 'hidden',
    },
  },

  '.spinner-grow': {
    zIndex: 1,
    position: 'absolute',
    top: 'calc(25% - .5rem)',
    left: 'calc(50% - .5rem)',
  },

  '.calendly-inline-widget': {
    zIndex: 10,
  },

  [breakpoints.Phablet]: {
    '.form-container': {
      marginTop: `0`,
    },
  },

  [breakpoints.Desktop]: {
    '.form-container': {
      marginTop: `-${rhythm(2)}`,
    },
  },
});

export default Styles;
