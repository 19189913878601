import React from 'react';

import Styles from './calendlyEmbed.styles';

class CalendlyEmbed extends React.Component {
  calendlyScriptSrc = 'https://assets.calendly.com/assets/external/widget.js';
  buildCalendlyUrl = (
    account,
    eventName,
    utmSource,
    utmMedium,
    utmCampaign,
    utmContent
  ) =>
    `https://calendly.com/${account}/${eventName}?hide_event_type_details=1&utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}&utm_content=${utmContent}`;

  componentDidMount() {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', this.calendlyScriptSrc);
    head.appendChild(script);
  }

  componentWillUnmount() {
    const head = document.querySelector('head');
    const script = document.querySelector('script');
    head.removeChild(script);
  }
  render() {
    const {
      account,
      eventName,
      utmSource,
      utmMedium,
      utmCampaign,
      utmContent,
    } = this.props;
    return (
      <Styles>
        <div
          className="calendly-inline-widget"
          data-url={this.buildCalendlyUrl(
            account,
            eventName,
            utmSource,
            utmMedium,
            utmCampaign,
            utmContent
          )}
          style={{ height: '480px', overflowY: 'hidden' }}
        />
      </Styles>
    );
  }
}

export default CalendlyEmbed;
